<template>
    <section class="position">
        <section class="position-wrapper wrapper">
            <CommonBlock title="Позиція Партії" :breadcrumbs="breadcrumbs" theme="dark">
                <div class="img">
                    <img :src="preview" alt="vaccination" v-if="preview">
                    <h5 class="position-description">{{title}}</h5>
                </div>
            </CommonBlock>
            <div class="page content custom-scroll">
                <h1>{{title}}</h1>
                <div class="position-text" v-html="body"></div>
            </div>
        </section>
    </section>
</template>

<script>
import {Hooper, Pagination as HooperPagination, Slide} from "hooper";
import "hooper/dist/hooper.css";
import axios from "axios";
import CommonBlock from "@/components/CommonBlock";

export default {
    components: {
        Hooper,
        Slide,
        HooperPagination,
        CommonBlock
    },
    data() {
        return {
            title: '',
            body: '',
            preview: '',
            menu_name: ''
        }
    },
    created() {
        document.title = 'loading...';
        this.refresh();
    },
    watch: {
        $route() {
            this.refresh();
        }
    },
    methods: {
        refresh() {
            axios.get('/api/position/view', {params: {id: this.$route.params.id}}).then(item => {
                this.title = item.data.title;
                this.body = item.data.text;
                this.preview = item.data.preview;
                this.menu_name = item.data.menu_name;
                document.title = this.title;
            });
        }
    },
    computed: {
        breadcrumbs() {
            return [
                {
                    id: 1,
                    name: 'Позиція партії',
                    link: '/position'
                }, {
                    id: 2,
                    name: this.menu_name,
                }
            ];
        }
    }
};
</script>

<style lang="less">
.position{
    background-color: #F5F5F5;
}

.position-wrapper .slot{
    margin-bottom: 0;
}
.position ol, .position ul{
    list-style-position: inside;
    padding-left: 0!important;
}
</style>
<style scoped lang="less">
.img img{
    width: 100%;
    height: auto;
    margin-bottom: 40px;
}
h1{
    font-size: 24px;
    font-weight: 500;
    letter-spacing: -0.392px;
    color: @blueColor;
    margin-top: 30px;
    @media all and (min-width: 950px) {
        display: none;
    }
}
.position {
    &-title {
        font-weight: bold;
        font-size: 56px;
        line-height: 64px;
        letter-spacing: -0.392px;
        color: #1a2744;
        margin-bottom: 144px;
    }

    &-img {
        margin-bottom: 40px;
    }

    &-description {
        font-weight: bold;
        font-size: 32px;
        line-height: 40px;
        letter-spacing: -0.224px;
        color: #ffc900;
        margin-bottom: 96px;
    }

    &-text {
        max-width: 1120px;
        margin: 0 auto;
    }
}

.hooper:focus {
    outline: none !important;
}

.btn-back {
    display: flex;
    align-items: center;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #1a2744;
    text-decoration: none;

    &_icon {
        width: 56px;
        height: 56px;
        border-radius: 50%;
        background: #ffc900;
        box-shadow: 0px 24px 24px -14px rgba(249, 198, 35, 0.35);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
    }
}

.position- .header {
    width: 100%;
    padding-top: 80px;
}

.header-logo {
    width: 253px;
    height: 51px;
    margin-bottom: 22px;
    display: inline-block;
}

.page-title {
    margin-bottom: 30px;
    font-weight: bold;
    font-size: 50px;
    line-height: 60px;
    letter-spacing: -0.42px;
    color: #1a2744;
}

.position-text {
    overflow-y: auto;
    p {
        margin-bottom: 20px;
    }
    @media all and (max-width: 950px) {
        padding-top: 20px;
        font-size: 16px;
    }
}
</style>

<style lang="less">
.position-text h4 {
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.224px;
    color: #1a2744;
    margin-bottom: 40px;
    @media all and (max-width: 950px) {
        font-size: 28px;
    }
}
.position-text ul {
    padding-left: 25px;
    text-align: justify;
}
.position-text li {
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.14px;
    color: #1a2744;
    margin-bottom: 20px;
    @media all and (max-width: 950px) {
        font-size: 16px;
    }
}
.position-text a{
    color: #ffc900!important;
    text-decoration: none;
    &:hover{
        text-decoration: underline;
    }
}
.common-block{
    .breadcrumb{
        li:last-child:before{
            display: none;
        }
        li a{
            color: #1a2744;
        }
    }
}
.common-block.deputies{
    .breadcrumb{
        li a {
            color: #ffc900;
        }
        li:last-child:before{
            display: none;
        }
    }
}
</style>
